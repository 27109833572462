<template>
  <div>
    <b-card class="light-box" v-if="showReward">
        <b-button block class="light-box" variant="outline-secondary" :href="reporturl">
          <div v-if="adherenceStatus == 1">
            <b-img :src="require('@/assets/lightbox-good.svg')"></b-img>
            <div class="title">{{T('Weekly Report')}}</div>
            <div class="message">{{T('Tap here to see more. You\'re doing great Keep it up!')}}</div>
          </div>
          <div v-if="adherenceStatus == 2">
            <b-img :src="require('@/assets/lightbox-avg.svg')"></b-img>
            <div class="title">{{T('Weekly Report')}}</div>
            <div class="message">{{T('Tap here to find out. You\'re doing good Almost there!')}}</div>
          </div>
          <div v-if="adherenceStatus == 3">
            <b-img :src="require('@/assets/lightbox-bad.svg')"></b-img>
            <div class="title">{{T('Weekly Report')}}</div>
            <div class="message">{{T('Tap here to learn how to improve')}}</div>
          </div>

          <div class="footer">{{T('Report expires in')}} {{reportTimer}} {{T('hours')}}</div>
        </b-button>
    </b-card>

    <b-card class="calendar" no-body>
      <template v-slot:header>
        <b-container fluid class="branding-header">
          <b-row align-v="center" class="text-center">
            <b-col id="hero-image">
              <b-img :src="require('@/assets/logo-branding.svg')"></b-img>
            </b-col>
          </b-row>
        </b-container>
        <b-container fluid class="title">
          <div>{{T('Your Schedule for')}}</div>
          <div>{{schedule}}</div>
        </b-container>
      </template>

      <b-card-body>
        <div v-if="video !== undefined">
          <b-button v-b-modal.video block class="button video-button">
            <div class="title">{{T('Instructions')}}</div>
          </b-button>
          <b-modal id="video" hide-footer :title="T('Instructions')">
            <b-embed v-if="video"
                     type="iframe"
                     aspect="16by9"
                     :src="`${video}`"
                     allowfullscreen>
            </b-embed>
          </b-modal>
        </div>
        <div v-if="adherenceStatus == 1">
          <b-button block class="button report weekly-good" :href="reporturl">
            <div class="title">{{T('Weekly Report')}}</div>
            <div class="body">{{T('Your weekly report is here! Keep up the good work')}}</div>
          </b-button>
        </div>
        <div v-if="reportTimer > 0 ">
        <div v-if="adherenceStatus == 2">
          <b-button block class="button report weekly-avg" :href="reporturl">
            <div class="title">{{T('Weekly Report')}}</div>
            <div class="body">{{T('Your weekly report is here! Tap here to find out more.')}}</div>
          </b-button>
        </div>
        <div v-if="adherenceStatus == 3">
          <b-button block class="button report weekly-bad" :href="reporturl">
            <div class="title">{{T('Weekly Report')}}</div>
            <div class="body">{{T('Your weekly report is here! Tap here to find out more.')}}</div>
          </b-button>
        </div>
        <div v-if="adherenceStatus != 0" class="report-mesg">
          {{T('Report expires in')}} {{reportTimer}} {{T('hours')}}
        </div>
        </div>
        <div class="dose-listing">
          <div v-if="activeDoses.length != 0">
            <div class="heading">{{T('Active Doses')}}</div>
            <b-button block class="button dose-active" :class="Language()"
                      v-for="a in activeDoses"
                      :key="a.ID"
                      @click="onTakeDose(a)">
              <div class="title">{{T('Dose')}} {{a.BlisterIndex}}</div>
              <div class="intake">{{T('Scheduled for')}} {{ a.Intake | displayTime(today) }}</div>
              <div class="body">{{T('Tap to confirm dose')}}</div>
            </b-button>
          </div>
          <div v-if="missedDoses.length != 0">
            <div class="heading">{{T('Missed Doses')}}</div>
            <b-button block class="button dose-missed"
                      v-for="m in missedDoses"
                      :key="m.ID"
                      @click="onTakeDose(m)">
              <div class="title">{{T('Dose')}} {{m.BlisterIndex}}</div>
              <div class="intake">{{T('Scheduled for')}} {{ m.Intake | displayTime(today) }}</div>
              <div class="body">{{T('Tap to confirm late intake')}}</div>
            </b-button>
          </div>
          <div v-if="upcomingDoses.length != 0">
            <div class="heading">{{T('Upcoming Doses')}}</div>
            <div class="button dose-upcoming"
                 v-for="u in upcomingDoses"
                 :key="u.ID">
              <div class="title">{{T('Dose')}} {{u.BlisterIndex}}</div>
              <div class="intake">{{T('Scheduled for')}} {{ u.Intake | displayTime(today) }}</div>
            </div>
          </div>
          <div v-if="closedDoses.length == 0" class="nodose-message">
            {{T('You have no scheduled doses left for today')}}.
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="doseConfirmationDialog"
      :title="T('Intake Dose Comfirmation')"
      :ok-title="T('Confirm')"
      :cancel-title="T('Cancel')"
      @ok="onOK">
      <div class="d-block text-center">
        <h3>{{T('Please take your dose')}}!</h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-cal/dist/vuecal.css";
import moment from "moment-timezone";
import 'moment/locale/es';

const hours = 60 * 60 * 1000;

function close(x) {
  return x.Status == "Close"
}

function diff(x, ref) {
  let intake = moment(x.Intake);
  return moment.duration(intake.diff(ref));
}

function active(ref) {
  let today = moment(ref);
  return x => {
    let d = diff(x, today);
    return (d <= hours) && (d >= -hours);
  };
}

function late(ref) {
  let today = moment(ref);
  return x => {
    let d = diff(x, today);
    return (d < -hours);
  };
}

function upcoming(ref) {
  let today = moment(ref);
  return x => {
    let d = diff(x, today);
    return (d > hours);
  };
}

export default {
  name: 'cTagCalendar',
  props: {
    events: {
      required: true,
    },
    today: {
      type: Date,
      default: () => new Date(),
    },
    videourls: {
      type: Array,
      required: true,
    },
    adherenceStatus: {
        type: Number,
    },
    expire: {
      type: Date,
    },
    showReward: {
      type: Boolean,
    },
    reporturl: {
      type: String,
    }
  },
  data() {
    return {
      tmpDose: {},
    };
  },
  computed: {
    schedule() {
      return moment(this.today).format("dddd MMMM Do, YYYY");
    },
    video() {
      if (this.videourls && (this.videourls.length > 0)) {
        let code = this.videourls[0];
        return `https://www.youtube.com/embed/${code}?rel=0`
      } else {
        return undefined;
      }
    },
    closedDoses() {
      return this.events.filter(close);
    },
    activeDoses() {
      return this.closedDoses.filter(active(this.today));
    },
    missedDoses() {
      return this.closedDoses.filter(late(this.today));
    },
    upcomingDoses() {
      return this.closedDoses.filter(upcoming(this.today));
    },
    reportTimer() {
      if (this.expire === undefined) {
        return -1
      }
      let t = moment()
        return moment.duration(t.diff(this.expire)).hours()
    },
  },
  filters: {
    displayTime: function(value) {
      if (!value) return "";
      return moment(value).format('hh:mm a');
    },
    timeSpan: function(value) {
      if (!value) return "";
      return moment(value).fromNow();
    }
  },
  methods: {
    onTakeDose(event) {
      if (event.Status == "Close") {
        this.tmpDose = event;
        this.$root.$emit("bv::show::modal", "doseConfirmationDialog");
      }
    },
    onOK() {
      this.$emit("confirm", this.tmpDose);
    },
    status(event) {
      return event.Status == "Close" ? "primary" : "success";
    }
  }
};
</script>

<style lang="scss" scoped>

$btn-height: 6em;

#hero-image img {
    padding: 1.2rem;
    width: 100%;
    max-width: 25em;
}

.calendar {
    font: 10pt Verdana, Geneva, sans-serif;
    @media (min-width: 375px) {
            font-size: 1.1em;
    }
    @media (min-width: 768px) {
            font-size: 1.3em;
    }
    @media (min-width: 1024px) {
            font-size: 1.5em;
    }

    .card-header {
        background-color: var(--secondary);
        margin: 0;
        padding: 0;
        border: 0
    }
    .card-header .title {
        background-color: var(--menu);
        color: theme-color(primary);
        font-weight: bold;
        font: 1.5em Verdana,Geneva,sans-serif;
        padding: .6em;
        text-align: center;
    }
}

.video-button {
    background-color: var(--button);
    background-image: url("../../assets/video-button.svg");
}

.calendar {
    .video-button, .dose-active, .dose-missed {
        text-align: left;
    }
    .button {
        border-radius: 0.50em;
        border: 0;
        background-position: right;
        background-repeat: no-repeat;
        background-size: $btn-height;
        height: $btn-height;
        margin: 0.8em 0;
        font-size: 0.9em;
        .title {
            font-size: 1.3em;
            font-weight: bold;
        }
    }
    .dose-listing .heading {
        font-size: 1.6em;
        font-weight: bold;
        margin: 1.5em 0;
        border-bottom: 0.2em solid gray;
    }
    .nodose-message {
        color: lighten(gray, 20);
        font-size: 1.6em;
        font-weight: bold;
        text-align: center;
        margin: auto;
    }
    .report-mesg {
        font-weight: bold;
        text-align: center;
    }
}

.button.report {
    padding-right: 6.8em;
    .title {
        font-size: 1.2em;
    }
    .body {
        font-size: .8em;
    }

}

 .button {
   color: rgba(255, 255, 255, .8);
   &:hover, &:active {
     color: rgba(255, 255, 255, .4);
   }
 }

 .button.dose-active {
   background-color: var(--dose-active);
   &.en {
     background-image: url("../../assets/active-dose-btn.svg");
   }
   &.es {
     background-image: url("../../assets/active-dose-btn-spanish.svg");
   }
 }

.button.dose-missed {
  background-color: var(--dose-missed);
  background-image: url("../../assets/missed-dose-btn.svg");
 }

.button.dose-upcoming  {
  display: block;
  text-align: center;
  padding: 0.375rem 0.75rem;
  background-color: var(--dose-upcoming);
}

.button.weekly-good {
    color: black;
    background-color: var(--adherence-good);
    background-image: url("../../assets/report-good.svg");
 }

.button.weekly-avg {
    color: black;
    background-color: var(--adherence-avg);
    background-image: url("../../assets/report-avg.svg");
}

.button.weekly-bad {
    color: black;
    background-color: var(--adherence-bad);
    background-image: url("../../assets/report-bad.svg");
}

.card.light-box {
    color: black;
    margin: 5%, auto;
    position: fixed;
    width: inherit;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    .title {
        font-weight: bold;
        font-size: 1.2em;
    }
    .footer {
        font-weight: bold;
    }
}

</style>
